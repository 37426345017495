.wrap {
  max-width: 1240px;
  &, & {
    width: 100%;
    padding: 0 25px;
    margin: 0 auto; } }

@for $i from 1 through 4 {
  $size: $i * 1.5rem;
  $x-size: $size * 0.5;
  .pt-#{$i} {
    padding-top: $size; }

  .pb-#{$i} {
    padding-bottom: $size; }

  .mt-#{$i} {
    margin-top: $size; }

  .mb-#{$i} {
    margin-bottom: $size; } }

%grid {
  display: grid;
  grid-template-columns: 1fr; }

[class*='grid-'] {
  grid-gap: 2rem; }

.grid-2, .grid-3, .grid-4, .grid-auto, .grid-reverse {
  @extend %grid; }

@media screen and (min-width: 42rem) {
  .grid-auto {
    grid-template-columns: 2fr 5fr; }

  .grid-reverse {
    grid-template-columns: 3fr 1fr; }

  .grid-2 {
    grid-template-columns: 1fr 1fr; }

  .grid-3 {
    grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr)); }

  .grid-4 {
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr)); } }

.active {
  color: var(--theme); }

.is {
  background: var(--theme);
  color: var(--light); }
