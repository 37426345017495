code {
  min-width: 100%;
  max-width: 100%;
  &.noClass {
    color: orange; } }
pre {
  display: grid;
  padding: 1rem;
  overflow-x: auto; }
.highlight {
  display: grid;
  margin: 1.25rem 0;
  box-shadow: 0 0 2.5rem rgba(0,0,0,0.12);
  border-radius: 4px;
  overflow: hidden;
  table {
    margin: 0;
    border-collapse: collapse;
    background-color: var(--overlay); }
  & & {
    margin: 0; } }

.lntd {
  &:first-child {
    width: 2.75rem;
    background-color: var(--theme);
    color: var(--light);
    pre {
      padding: 0; }
    code {
      display: flex;
      flex-flow: column;
      align-items: center; } }
  &:last-child {
    padding-left: 0; } }


.err {
  color: #a61717;
  background-color: #e3d2d2; }

.hl {
  display: block;
  width: 100%;
  background-color: #ffffcc; }

.ln {
  margin-right: 0.4rem;
  padding: 0 0.4em 0 0.4rem;
  color: #666; }

.k, .kc, .kd, .kn, .kp, .kr, .kt, .nt {
  color: #6ab825;
  font-weight: 500; }

.kn , .kp {
  font-weight: 400; }

.nb, .no, .nv {
  color: #24909d; }

.nc, .nf, .nn {
  color: #447fcf; }

.s, .sa, .sb, .sc, .dl, .sd, .s2, .se, .sh, .si, .sx, .sr, .s1, .s {
  color: #ed9d13; }

.m, .mb, .mf, .mh, .mi, .il, .mo {
  color: #3677a9; }

.ow {
  color: #6ab825;
  font-weight: 500; }

.c, .ch, .cm, .c1 {
  color: #999;
  font-style: italic; }

.cs {
  color: #e50808;
  background-color: #520000;
  font-weight: 500; }

.cp, .cpf {
  color: #cd2828;
  font-weight: 500; }

.gd, .gr {
  color: #d22323; }

.ge {
  font-style: italic; }

.gh, .gu, .nd, .na, .ne {
  color: #ffa500;
  font-weight: 500; }

.gi {
  color: #589819; }

.go {
  color: #ccc; }

.gp {
  color: #aaa; }

.gs {
  font-weight: 500; }

.gt {
  color: #d22323; }

.w {
  color: #666; }
